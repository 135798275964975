import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BlogTable } from "../../../components/Blog/postElements/BlogTable";
import { NewsletterBanner } from "../../../components/NewsletterBanner";
import { ImageMaxWidth } from "../../../components/Blog/postElements/ImageMaxWidth";
import { CustomStyleElement } from "../../../components/Blog/postElements/CustomStyleElement";
import { PostIntro } from "../../../components/Blog/postElements/PostIntro";
export const _frontmatter = {
  "title": "How Do I Integrate Braintree Payments Into My Flutter Mobile App?",
  "categories": ["mobile payments", "fintech", "flutter", "technical"],
  "authors": ["Marek Gocał"],
  "abstract": "In this article, we walk you step-by-step through using Braintree for Flutter. Braintree for Flutter is a plug-in that’s essential if you want to build Braintree into a mobile commerce app with a shared iOS & Android codebase. Read the article to learn more.",
  "imageSource": "./main.png",
  "link": "braintree-flutter-integration",
  "date": "2021-07-13T08:23:04.259Z",
  "seoTitle": "How to integrate braintree payments in mobile app",
  "seoDescription": "How to easily integrate mobile payments using Braintree in your mobile app via Flutter Braintree SDK implementation details in Flutter. Venmo, Google Pay, PayPal and more",
  "seoKeyPhrase": "braintree integration guide ",
  "seoKeyWords": "m-commerce, mcommerce, mobile commerce, Flutter, Braintree. payment processor, payment gateway, PayPal, Google Pay, Android, iOS, Braintree sandbox, Venmo, mobile payments, in-app payments, SDK, software development kit, client-side implementation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PostIntro title="Introduction" description={<p><b>Mobile commerce (or m-commerce) is now as good as universal in technologically developed countries. Wherever smartphones are widely available, customers expect to be able to use them to pay for goods and services with an app or contactless payment.</b></p>} imageUrl={"/img/howToIntegrateBraintree/5.-easily-integrate-mobile-payments-using-braintree.png"} mdxType="PostIntro" />
    <p><strong parentName="p">{`Luckily for developers serving this market, this rapid rise in demand has been matched by payment gateway providers’ improvements to payment systems, greatly simplifying the integration process.`}</strong></p>
    <p>{`Based on our experience, we regard `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{` as the industry leader, particularly for the wide range of payment methods it supports. The `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{` SDK allows developers to implement and customise payment systems for mobile applications, and also offers dedicated UIs for Android and IOS.`}</p>
    <p>{`But what about cross-platform apps? In the case of mobile applications, `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{` officially supports only their native technologies, which is a limitation. If developers want an m-commerce app to share a codebase between platforms, we have to reach for plug-ins that wrap the `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{` SDK. In the following detailed step-by-step guide, I introduce one such package, Flutter_braintree known as `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{` for Flutter.`}</p>
    <h2 {...{
      "id": "Part-I-Braintree-account-registration"
    }}>{`Part I: Braintree account registration`}</h2>
    <p>{`First, we need to register to access `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{`. For beginners, I recommend setting up a free sandbox account that allows you to test all functions in a safe environment. Later, when we want to make real transactions, we can easily replace the data. But let’s start with what the sandbox is and how it differs from a production account.`}</p>
    <p>{`The sandbox is a test environment that reproduces the production environment with minor differences. In the sandbox, we can also create users and roles or carry out transactions using test credit card numbers.`}</p>
    <p>{`What are the other differences?`}</p>
    <BlogTable type="mixed" mdxType="BlogTable">
  | Functions | SandBox | Production | | ------------------------------- |
  ---------------------------------------------------------------------------------------------------
  |
  -------------------------------------------------------------------------------------------------------------------------------------------------
  | | Login URLs | [Login link](https://www.braintreegateway.com/login) | [Login
  link](https://www.braintreegateway.com/login) | | Payment methods accepted |
  Test credit cards/payment methods | Real creadit cards/payment methods | |
  Processor responses | Triggered by specific test values | Returned by
  processing banks | | Hardware resources | No dedicated hardware resources –
  can't withstand high transaction volumes | Dedicated hardware resources – can
  withstand high transaction volumes | | Purge and delete capabilities | Can
  purge transactions; Vault records; payment methods; addresses; subscriptions;
  and sub-merchants | Must manually delete Vault records; payment methods;
  addresses; and subscriptions; must manually suspend sub-merchants; can't
  delete transactions | | Webhooks | Triggered by specific test values |
  Triggered by actual events in the gateway | | Processing options and settings
  | Standard processing options are displayed | Processing options may be
  limited based on account setup | | Disputes | Chargebacks can be created in
  the Control Panel; and you can simulate winning or losing a dispute | The way
  you manage chargebacks depends on your account setup – not all accounts have
  disputes available in the Control Panel | | Location | Determined by the
  [country selection](https://www.braintreepayments.com/pl/country-selection)
  made at the time of signup | Determined by the country in which your business
  is registered | | Recurring Billing | Limited to 100000 active subscriptions | Unlimited number of subscriptions |
    </BlogTable>
    <p><em parentName="p">{`Source: Braintree - Try It Out/Sandbox versus production - `}<a parentName="em" {...{
          "href": "https://articles.braintreepayments.com/get-started/try-it-out",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`https://articles.braintreepayments.com/get-started/try-it-out`}</a></em></p>
    <p>{`However, you should note that the sandbox account is not linked to the production account, so nothing created in the sandbox can be moved over.`}</p>
    <p>{`After registration, you will have access to a Sandbox merchant ID, a public key, a private key and you will be able to generate your tokenisation key.`}</p>
    <ImageMaxWidth imageSrc="/img/howToIntegrateBraintree/image5.jpg" alt="Merchant ID" maxWidth={630} className="mb" mdxType="ImageMaxWidth" />
    <ul>
      <li parentName="ul">{`Merchant ID is the unique identifier for your entire gateway account. You will have separate merchant IDs for sandbox and production accounts.`}</li>
      <li parentName="ul">{`Public Key is a user-specific public identifier. Each user associated with your `}<a parentName="li" {...{
          "href": "https://www.braintreepayments.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Braintree`}</a>{` gateway has their own public key.`}</li>
      <li parentName="ul">{`Private Key is another user-specific private identifier. Your private key should never be shared beyond the use of an API call. Do not share it with anyone.`}</li>
      <li parentName="ul">{`Tokenisation keys can be used for authorisation in your `}<a parentName="li" {...{
          "href": "https://www.braintreepayments.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Braintree`}</a>{` integration. They authorise the client SDK to tokenise payment information for use on your server.`}</li>
    </ul>
    <ImageMaxWidth imageSrc="/img/howToIntegrateBraintree/image7.jpg" alt="Tokenization Keys" maxWidth={420} mdxType="ImageMaxWidth" />
    <h2 {...{
      "id": "Part-II-Using-the-Flutter-plug-in"
    }}>{`Part II: Using the Flutter plug-in`}</h2>
    <p>{`Now let’s move on to configuration of the Flutter application itself. Follow the steps below to complete this for both platforms.`}</p>
    <h3 {...{
      "id": "Step-1-First-we-have-to-import-our-flutter_braintree-package"
    }}>{`Step 1: First, we have to import our flutter_braintree package.`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`dependencies:
  ...
flutter_braintree: <version>`}</code></pre></div>
    <h3 {...{
      "id": "Step-2-Android-setup"
    }}>{`Step 2: Android setup`}</h3>
    <p><strong parentName="p">{`Migration to AndroidX`}</strong></p>
    <p>{`In `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/app/build.gradle`}</code>{` we need to set our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`minSdkVersion`}</code>{` to at least 21.`}</p>
    <p><strong parentName="p">{`Card scanning`}</strong></p>
    <p>{`Card scanning is a process in which the user can use his camera module to take a photo of his payment card. The photo is used only for this single operation and won’t be saved or sent anywhere. The system just reads the necessary information so that the user doesn’t have to enter it manually. By default, this feature is disabled to reduce the size of the app. If we want to allow our users to scan their credit cards instead of typing card details, we need to add the following line to our build.gradle file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`dependencies {
...
implementation 'io.card:android-sdk:5.+'
}`}</code></pre></div>
    <p><strong parentName="p">{`PayPal/Venmo/3D Secure`}</strong></p>
    <p>{`If we want to support PayPal, Venmo or 3D Secure payments, we have to allow for the browser switch by adding an intent filter to the AndroidManifest.xml inside the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<application>`}</code>{` body:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<activity android:name="com.braintreepayments.api.BraintreeBrowserSwitchActivity"
  android:launchMode="singleTask">
  <intent-filter>
    <action android:name="android.intent.action.VIEW" />
    <category android:name="android.intent.category.DEFAULT" />
    <category android:name="android.intent.category.BROWSABLE" />
    <data android:scheme="\${applicationId}.braintree" />
  </intent-filter>
</activity>`}</code></pre></div>
    <p>{`{applicationId} corresponds to the app’s package ID so if our package ID is com.your-company.your-app then the URL scheme should be com.your-company.your-app.braintree`}</p>
    <p><strong parentName="p">{`Google Pay`}</strong></p>
    <p>{`Add the wallet-enabled meta-data tag to your AndroidManifest.xml inside the application body:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<meta-data android:name="com.google.android.gms.wallet.api.enabled" android:value="true"/>`}</code></pre></div>
    <p><img parentName="p" {...{
        "src": "https://lh6.googleusercontent.com/LmeGuFlBbX6LMCLT4W1tgcdNPPx3a1HIGLgJi8BTJrDvW1vs84ySfqK00VNgnR-jIs4v4QlKmeVFa3ofqXA9W_Zu01RbqQd8SXqS4VwYTSQvV8aN8LutT-l33gbQo3O7BzAsPNEb",
        "alt": null
      }}></img></p>
    <p>{`IMPORTANT! We strongly recommend enabling Two-Factor Authentication to secure the account. This option is available in the user settings at the bottom of the screen as seen in the screenshot above.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/howToIntegrateBraintree/image11.png",
        "alt": null
      }}></img></p>
    <h3 {...{
      "id": "Step-3-iOS-setup"
    }}>{`Step 3: iOS setup`}</h3>
    <p>{`You may need to add or uncomment the following line at the top of your iOS Podfile:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`platform :ios, '12.0'`}</code></pre></div>
    <p><strong parentName="p">{`PayPal/Venmo/3D Secure`}</strong></p>
    <p>{`In your App Delegate or your Runner project, you need to specify the URL scheme for redirecting payments as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`BTAppContextSwitcher.setReturnURLScheme("com.your-company.your-app.payments")`}</code></pre></div>
    <p>{`Next, we have to specify the same URL scheme in your Info.plist:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<key>CFBundleURLTypes</key>
<array>
  <dict>
    <key>CFBundleTypeRole</key>
    <string>Editor</string>
    <key>CFBundleURLName</key>
    <string>com.your-company.your-app.payments</string>
    <key>CFBundleURLSchemes</key>
    <array>
      <string>com.your-company.your-app.payments</string>
    </array>
  </dict>
</array>`}</code></pre></div>
    <p>{`That should be enough for an iOS implementation. If you are interested in exploring the topic in depth, this is the official `}<a parentName="p" {...{
        "href": "https://developers.braintreepayments.com/guides/paypal/client-side/ios/v4",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree documentation`}</a>{`, where you can also find SDKs for Android and Javascript.`}</p>
    <p>{`If you got lost somewhere along the way, you can always check out the `}<a parentName="p" {...{
        "href": "https://github.com/pikaju/flutter-braintree/tree/master/example",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`sample project`}</a>{`.`}</p>
    <CustomStyleElement className="italic" mdxType="CustomStyleElement">_Before we go any further, let’s check if what I’ve written above is easy for you - or, perhaps, you already see you’ll need help integrating payments into your app? If it’s the latter, feel free to contact us on [flutter@allbright.io](mailto:marek@allbright.io). We can help!_</CustomStyleElement>
    <h2 {...{
      "id": "Drop-in-UI"
    }}>{`Drop-in UI`}</h2>
    <p>{`Now that we have completed the preparation and setup stage, we can implement one of `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree’s`}</a>{` best features; it’s Drop-in UI.`}</p>
    <p>{`If you like, you can create your own interface using the methods provided by the flutter_braintree package. However, if you decide to use the prebuilt UI, it is very easy to implement.`}</p>
    <p>{`Let’s start with importing the plugin into our UI file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`import 'package:flutter_braintree/flutter_braintree.dart';`}</code></pre></div>
    <p>{`Then we start creating a request:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`final request = BraintreeDropInRequest(
clientToken: '<Insert your client token here>',
collectDeviceData: true,
googlePaymentRequest: BraintreeGooglePaymentRequest(
totalPrice: '4.20',
currencyCode: 'USD',
billingAddressRequired: false,
),
paypalRequest: BraintreePayPalRequest(
amount: '4.20',
displayName: 'Example company',
),
);`}</code></pre></div>
    <p>{`It is up to you to decide which payment methods you enable for your users. For example, if you are not interested in Google Pay, simply delete `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`googlePaymentRequest:`}</code>{` or if you want to add Venmo then add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`venmoEnabled: true`}</code></p>
    <p>{`After creating a request of this kind, we can call Drop-in UI to which we send our request:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`BraintreeDropInResult result = await BraintreeDropIn.start(request);`}</code></pre></div>
    <p>{`Then to access the payment Nonce just call:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`if (result != null) {
print('Nonce: \${result.paymentMethodNonce.nonce}');
} else {
print('Selection was canceled.');`}</code></pre></div>
    <p>{`But what if you don’t want to use the prebuilt UI? Just call your chosen payment method directly like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`final request = BraintreeCreditCardRequest(
cardNumber: '4111111111111111',
expirationMonth: '12',
expirationYear: '2021',
cvv: '367'
);`}</code></pre></div>
    <p>{`And this is where the implementation in Flutter ends. Below, you can find the file repository and FAQs.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/pikaju/flutter-braintree/tree/master/example",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Repository with Sample Project`}</a></p>
    <p>{`That’s all, for now!`}</p>
    <p>{`We hope you found the information presented in this article useful. If you need help integrating payment processing into your app, please feel free to contact us on `}<a parentName="p" {...{
        "href": "mailto:marek@allbright.io",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`marek@allbright.io`}</a>{`.`}</p>
    <h2 {...{
      "id": "FAQs"
    }}>{`FAQs`}</h2>
    <CustomStyleElement style={{
      marginBottom: "-20px",
      marginTop: "20px"
    }} mdxType="CustomStyleElement">
    **Q: Are all payment methods supported by this package?**
    </CustomStyleElement>
    <p>{`A: The Flutter package is in continuous development, so please note that some features, such as Samsung Pay, may not be available yet. This can change at any time so my advice is to check the latest current documentation.`}</p>
    <CustomStyleElement style={{
      marginBottom: "-20px"
    }} mdxType="CustomStyleElement">
    **Q: After adding the Key I have the following error:**
    </CustomStyleElement>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Unhandled Exception:

PlatformException(braintree_error, Merchant account not found, null)`}</code></pre></div>
    <p>{`A: Usually this problem is due to a bad `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{` account configuration, not a package error.`}</p>
    <p>{`For more about this, check:`}</p>
    <p><a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/41757631/paypal-with-braintree-saying-merchant-account-not-found",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`https://stackoverflow.com/questions/41757631/paypal-with-braintree-saying-merchant-account-not-found`}</a></p>
    <CustomStyleElement style={{
      marginBottom: "-20px"
    }} mdxType="CustomStyleElement">
    **Q: What causes “Type mismatch: inferred type is MainActivity but FlutterEngine was expected”?**
    </CustomStyleElement>
    <p>{`A: This can be solved by upgrading Flutter to the newest stable version`}</p>
    <CustomStyleElement style={{
      marginBottom: "-20px"
    }} mdxType="CustomStyleElement">
    **Q: I have an “Exception with PayPal payment on iOS”**
    </CustomStyleElement>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`PlatfomException (PlatformException(braintree_error, The operation couldn’t be completed. Application does not support One Touch callback URL scheme, null))`}</code></pre></div>
    <p>{`A: You must ensure that the URL is exactly the same as the one specified in Info.plist`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Sources:`}</em></strong></p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://stackoverflow.com/questions/41757631/paypal-with-braintree-saying-merchant-account-not-found",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`https://stackoverflow.com/questions/41757631/paypal-with-braintree-saying-merchant-account-not-found`}</a></em><br />{`
`}<em parentName="p"><a parentName="em" {...{
          "href": "https://braintreegateway.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`https://braintreegateway.com`}</a></em><br />{`
`}<em parentName="p"><a parentName="em" {...{
          "href": "https://developers.braintreepayments.com/guides/paypal/client-side/ios/v4",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`https://developers.braintreepayments.com/guides/paypal/client-side/ios/v4`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      